import React, { useState, useEffect, useRef } from 'react';
import { Row, Form, FormControl, Button } from 'react-bootstrap';
import Select from "react-select";


import AWS from 'aws-sdk';
import aws_exports from '../aws-exports';

import "./SelectMultiCounters.css";


const SelectMultiCounters = (props) => {
	//export default function ViewerCanvas(props) {

    const [selectedCounters, setSelectedCounters] = useState([])
	

 const onFetch = () => {

    props.callback(selectedCounters);
 }   


	useEffect(


		() => {
            setSelectedCounters([])
		},
		[ props.selectedOrg]
	);

	return  ( 

<div className="selwrapper">
  {/* First row with label on the left and button on the right */}
  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
  <h5 className="gray-text"> Select Counters </h5>
    <button    onClick={onFetch }   className = "btn-fetch">Fetch counts</button>
  </div>

  {/* Second row with full-width Select */}
  <Select
    options={props.countersList}

    isMulti
    className={"organization-select"}
    value={selectedCounters}
    onChange={setSelectedCounters}
    ignoreAccents={false} // Má fjarlægja
    styles={{ container: (base) => ({ ...base, width: "100%" }) }}
  />
</div>


    );
};

export default SelectMultiCounters;


