import React, { useState, useEffect } from "react";
import useFetch from "./useFetch";
import { CED } from "../Components/CED";
import Tables from "./Table";
import { FieldGroup } from "../Components/Forms";
import { Table, Row, Col } from "react-bootstrap";
import { Button, ButtonGroup, ToggleButton, OverlayTrigger, Popover  } from 'react-bootstrap';

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";


import { Form,FormControl, FormGroup } from 'react-bootstrap'
import { useInput } from "./useInput";
import Amplify, { graphqlOperation, API, Auth } from "aws-amplify";
import BootstrapTable from "react-bootstrap-table-next";

import Select from "react-select";
import moment from "moment";
import _ from "lodash";
import Threshold from "./Threshold.js";
import "./CountDeviations.css";
import "./Threshold.css";

//import awsList from "../sqlData/currentAwsList.json";
//import currentList from "../sqlData/counterID.json";

import aws_exports from "../aws-exports";

Amplify.configure(aws_exports, {});

/* Fyrsta skref Sækja Organisation List og byrta listan. */

var cntUpdates = 0;
var selectedRowIndex = -1;

var errTxt = ["All Fields Must Be Filled - submit cancelled!",  
              "Illegal ID Value - submit cancelled!"
];



const FileFill_Title = "Filefill (%)";
var FileFill_Value = 95;
var FileFill_Active = false;



const DiskSpace_Title = "Disk space (MB)";
var DiskSpace_Value = 50000;
var DiskSpace_Active = false;


const DisSymmetry_Title = "DisSymmetry";
var DisSymmetry_Value = 50;
var DisSymmetry_Active = false;


const Deviations_Title = "Deviations";
var Deviations_Value = 2;
var Deviations_Active = false;


export default function CountDeviations(props) {
 

  const [editState, setEditState] = useState(true);
  

  const [ErrorMsg, setErrorMsg] = useState("");

  const [orgList, setOrgList] = useState([]);
  const [TheData, setTheData] = useState([]);


  const [orgSelected, setOrgSelected] = useState (-1);

  let d = new Date(); // today!
  const [startDate, setStartDate] = useState ( d.setDate(d.getDate() - 7));  

  const [endDate, setEndDate] = useState (new Date()); 
  const [endDateChecked, setEndDateChecked] = useState (false) 





  const callback  = (title, active, value) => {

    switch(title) {
      case FileFill_Title:
        FileFill_Value = value;
        FileFill_Active = active;
        break;

        case DiskSpace_Title:
          DiskSpace_Value = value;
          DiskSpace_Active = active;
        break;

        case DisSymmetry_Title:
          DisSymmetry_Value = value;
          DisSymmetry_Active = active;
        break;

        case Deviations_Title:
          Deviations_Value = value;
          Deviations_Active = active;
        break;
  
      default:
        break;
    }


  } 


  /* Create  */
  useEffect(() => {
    //If we change the state remove the org
  //  if (editState === false) {
   //   setAddFarmsToOrg([]);
      //clearUserInputs();
   // }

   loadOrgList();
 
  
  }, [cntUpdates]);


  const loadOrgList = async () => {


    //We need an ID for new Population
    
    let apiName = "vakicloudRdsAPI";
    let path = "/counters/getorglist";
    
    // https://h93jh4d41f.execute-api.eu-west-1.amazonaws.com/dev/counters
    //Get ID for pop
    let myInit = {
    
    response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {
       

        
      }  
    
    };
   
    setErrorMsg("");
    API.get(apiName, path, myInit)
    .then ((response) => {

        setOrgList (response.message);
 

    




     
        
     // TrgPop.PopId = newPopId+1;

    
     
    
    
    return;
    
    })
    .catch ((er) => {
      setErrorMsg ("Error fetching counter types "+JSON.stringify(er));
  
    return;
    
    });
    
      
    


};





  const fetchData = async () => {


    let strSel = "";
    let strThreshold = "";
/////////////////////////////////////////////////    






///////////////////////////////////////////////
let strStart = new Date (startDate).toISOString();
strStart = strStart.substring (0, 10 ) 


let dt = new Date (endDate);
 dt= dt.setDate(dt.getDate() +1);;  



let strEnd = new Date (dt).toISOString();
strEnd = strEnd.substring (0, 10 ) 


setTheData([])

    strSel = "Short = 0 and DateChecked > '"+strStart+"' and DateChecked < '"+strEnd+"'";
  



    if (orgSelected > 0){
      strSel += " and o.Organisation_ID = "+orgSelected;
    }




    if (FileFill_Active){
      if (strThreshold.length > 0)
        strThreshold += " or";
      strThreshold += " FileFill < "+(FileFill_Value*10);
    }

    if (DiskSpace_Active){
      if (strThreshold.length > 0)
        strThreshold += " or";
      strThreshold += " DiskFree < "+DiskSpace_Value;
    }

  

    if (DisSymmetry_Active){
      if (strThreshold.length > 0)
        strThreshold += " or";
      strThreshold += " (DisSymmetry > "+DisSymmetry_Value +" and DisSymmetry < 999999)";
    }
    
    
    if (Deviations_Active){
      if (strThreshold.length > 0)
        strThreshold += " or";
      strThreshold += " Deviations > "+Deviations_Value;
    }



    if (strThreshold.length > 0 )
      strSel += " and ("+strThreshold+") "

    //We need an ID for new Population
    



    let apiName = "vakicloudRdsAPI";
    let path = "/counters/getcountdeviations";
    
    // https://h93jh4d41f.execute-api.eu-west-1.amazonaws.com/dev/counters
    //Get ID for pop
    let myInit = {
    
    response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {
        selString: strSel
       

        
      }  
    
    };
   
    setErrorMsg("");
    API.get(apiName, path, myInit)
    .then ((response) => {
  

   

      let len = response.message.length;
      for (let i = 0; i<len; i++){
        response.message[i]["key"] = i;
        let s = response.message[i].DisSymmetry;
        response.message[i].DisSymmetry = s.padStart(4, " ");
      }


setTheData (response.message)


     
        
     // TrgPop.PopId = newPopId+1;

    
     
    
    
    return;
    
    })
    .catch ((er) => {
      setErrorMsg ("Error countlogs "+JSON.stringify(er));
  
    return;
    
    });
    
      
    


};




var OrgOptions = [];


OrgOptions.push ( <option key={-1} value= {-1}> {"  "} </option>)



if (orgList.length > 0){

    for (let i = 0; i<orgList.length; i++){
        let opt = <option key={orgList[i].Organisation_ID} value= {orgList[i].Organisation_ID}> {orgList[i].Name } </option>;
        OrgOptions.push(opt);
    }


}

  
let OrgSelect = <div className="single-threshold">

<Row>
    <div  className="firstrow">
        <div id="label"> Organisation </div>  
    </div>
    </Row>
    <Row>
        <div id="value">

        <Form.Control as = "select" aria-label="Default select example"   onChange = {(e)=>setOrgSelected (e.target.value)} value = {orgSelected}  >
{OrgOptions}
    </Form.Control>

        </div>
        </Row>
        </div>;




   let StartDate = <div className="float-left">

       <div className="datelabel">
Start date
        </div>

           <div className="dateinput">
            <DatePicker
             selected={startDate}
             onChange={(d)=>setStartDate (d)}

            dateFormat = "yyyy-MM-dd"
            disabled = {false}
        />
           </div>
            </div>;
   
   
let EndDate = <div className="float-left">
<div className="datelabel">
End date
 </div>

    <div className="dateinput">
     <DatePicker className = "dddd"
      selected={endDate}
      onChange={(d)=>setEndDate (d)}

     dateFormat = "yyyy-MM-dd"
     disabled = {false}
 />
    </div>

  
    </div>;














 /*
"Organisation": "Aquachile",
"Counter_ID": 4550,
"FileFill": 997,
"DiskFree": 43081,
"Deviations": 0,
"DisSymmetry": 36,
"File": "20240213_SEL_TK2045_TASD_0.mse",
"Text": "",
"DateChecked": "2024-02-14T01:07:12.000Z"
*/


function  padLeft (val){
  var x = val.toString().padStart(4, "0");



  return  x;


}


const HeaderFormatter = (col, colIdx) => {
  let _a = colIdx; 
 
  if (!col.tooltip || col.tooltip=='')
    return col.text;


  let toShow = col.text; 


  return <OverlayTrigger delay={400} rootClose={true} placement='left'  overlay={<Popover name = 'ColHeaderPopover' > {col.tooltip} </Popover >}>
  <div>
    {toShow}
      </div>
  </OverlayTrigger >



}





  let columns = [
    {dataField: "Organisation",
    sort : true,
    
    text: "Organisation"

  },
  {
    dataField: 'Product_Serial',
    sort : true,
    text: 'Counter'

  },
    {
    dataField: 'Counter_ID',
    sort : true,
    text: 'ID'

  },
  {
    dataField: 'FileFill',
    text: 'Fill %',
   sort: true,
   headerFormatter: HeaderFormatter,
    tooltip: "Reflect the number of scanlines which were saved and stored"

  }, 
  {
    dataField: 'DiskFree',
    text: 'Disk Free',
    headerFormatter: HeaderFormatter,
    sort: true
  
  }, 
/*
  "Deviations": 0,
  "DisSymmetry": 36,
  "File": "20240213_SEL_TK2045_TASD_0.mse",
  "Text": "",
  "DateChecked": "2024-02-14T01:07:12.000Z"
*/

  {
    dataField:  'Deviations',
    text: 'Dev',
    sort: true,
    headerFormatter: HeaderFormatter,
    tooltip: "Deviations on Visibility curve.  Number of pixel where the blue curve goes under 100"
  
  }, 
  {
    dataField:  'DisSymmetry',
    text: 'DisSymmetry', 
    sort: true,
    headerFormatter: HeaderFormatter,
    tooltip: "Only for single camera counters.  Difference (in pixels) in the length of the spaces to left and right of the active channels (visibility curve)"
   

  
  }, 
  {
    dataField:  'DateChecked',
    text: 'Date',
    sort:true,
    headerFormatter: HeaderFormatter,
    tooltip: "The time this record was checked for deviations"
  
  }, 
  {
    dataField:  'File',
    text: 'File',
    sort: true
  
  }, 
  {
    dataField:  'Text',
    text: 'Text',
    sort: true,
    headerFormatter: HeaderFormatter,
    tooltip: "May contain some message from the process of valuating this count"
   }

];






//xs, sm, md, lg, xl and xxl)
  return (
    <div className="flex-container" >

  <div className="toprowitemswrap">
 
  {StartDate}

{EndDate}

<div className="float-right">
  <Button variant="outline-secondary" type="Button" block           
           onClick={() =>
             fetchData(  )
           }
          
          > Fetch data </Button>
</div>
</div>
    
 

 
<Row>
    <Col xs={2}  l={1}>
  
          <div className="thresholdcolumn">


            {OrgSelect}

            <Threshold Title={FileFill_Title} Value={FileFill_Value} Active={FileFill_Active}  Callback = {callback}/>
            <Threshold Title={DisSymmetry_Title} Value={DisSymmetry_Value} Active={DisSymmetry_Active}  Callback = {callback} />
            <Threshold Title={DiskSpace_Title} Value={DiskSpace_Value} Active={DiskSpace_Active}  Callback = {callback}/>
            <Threshold Title={Deviations_Title} Value={Deviations_Value} Active={Deviations_Active}  Callback = {callback}/>


    


          </div>
</Col>
<Col xs={10} l={11}> 
          <div className="TableWrap">
        <BootstrapTable
            hover
            keyField='key'
            data={TheData}
            columns={columns}
 
           

          />
          </div>

</Col>

     
        
</Row>

    </div>
  );
}